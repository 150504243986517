import { Button } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const SubmitButton = ({
  block,
  loading,
  messageId,
  size,
  shape,
}) => (
  <Button
    block={block}
    htmlType="submit"
    loading={loading}
    size={size}
    type="primary"
    shape={shape}
  >
    <span>
      <FormattedMessage id={messageId} />
    </span>
  </Button>
)

SubmitButton.propTypes = {
  block: PropTypes.bool,
  loading: PropTypes.bool,
  messageId: PropTypes.string.isRequired,
  size: PropTypes.string,
  shape: PropTypes.string,
}

SubmitButton.defaultProps = {
  block: false,
  loading: false,
  size: undefined,
  shape: undefined,
}

export default SubmitButton
