import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Typography,
  Layout,
  Button,
  message,
} from 'antd'
import EmailValidator from 'email-validator'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useState } from 'react'

import RaffleActions from '../actions/raffles'
import Logo from '../components/Layout/logo.svg'
import I18nProvider from '../components/I18nProvider'
import SubmitButton from '../components/SubmitButton'

const Unsubscribe = ({ location }) => {
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState('form')
  const [error, setError] = useState('')

  // get email from the location object or the query string
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const hash = location.state ? location.state.hash : params.hash
  const id = location.state ? location.state.id : params.id
  const email = location.state ? location.state.email : params.email

  const handleFinish = async (values) => {
    try {
      if (!EmailValidator.validate(email) || !hash || !id) {
        setError('Something\'s not right, Please try again later!')
        return
      }
      const { reason } = values
      setLoading(true)
      const resp = await RaffleActions.unsubscribe(hash, id, email, reason)
      if (resp.status === 'not-found') {
        setError('The email you provided was not found in our system, Please try again!')
      } else setError('')

      setLoading(false)
      setState('complete')
    } catch (ex) {
      setLoading(false)
      setState('complete')
      setError('Something\'s not right, Please try again later!')
      message.warn(ex.message)
    }
  }

  const renderView = () => {
    // render unsubscribe form
    if (email && EmailValidator.validate(email)) {
      return (
        <Card
          style={{ width: 600 }}
          title={(<img alt="Raffskie Admin" src={Logo} width={200} />)}
        >
          {state === 'form' && (
          <>
            <Typography.Title level={5}>
              Unsubscribe
              {' '}
              <span style={{ color: '#1890ff' }}>
                {' '}
                {email}
                {' '}
              </span>
              {' '}
              from Raffskie&apos;s Mailing List
            </Typography.Title>
            <Form onFinish={handleFinish} layout="vertical">
              <Form.Item label="Reason" name="reason">
                <Input.TextArea maxLength={100} showCount />
              </Form.Item>
              <Form.Item>
                <Button.Group size="large">
                  <SubmitButton
                    loading={loading}
                    messageId="common.unsubscribe"
                  />
                </Button.Group>
              </Form.Item>
            </Form>
          </>
          )}
          {error !== '' && (
          <Typography.Title level={5}>{error}</Typography.Title>
          )}
          {state === 'complete' && error === '' && (
          <Typography.Title level={5}>
            <span style={{ color: '#1890ff' }}>
              {' '}
              {email}
              {' '}
            </span>
            {' '}
            has been removed from Raffskie&apos;s Mailing List. Please allow 24 hours to reflect this.
          </Typography.Title>
          )}
        </Card>
      )
    }
    return (<></>)
  }

  return (
    <I18nProvider>
      <Layout>
        <div className="container my3">
          <Row
            justify="center"
            type="flex"
            style={{
              background: '#f0f2f5',
              height: '100vh',
              paddingTop: '6rem',
            }}
          >
            <Col>{renderView()}</Col>
          </Row>
        </div>
      </Layout>
    </I18nProvider>
  )
}

Unsubscribe.propTypes = {
  location: PropTypes.object.isRequired,
}

export default Unsubscribe
